 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { BaseService, useServiceStore } from "@opendash/core";

import Parse from "parse";

import {
  TenantConfig,
  TenantDomain,
  Invoice,
  InvoicePage,
  GlossaryEntry,
  Video,
} from "./types";











export class AppService extends BaseService {
  constructor() {
    super({
      initialState: {
        tenantId: null,
        tenantIdNotFound: false,
        tenantConfig: undefined,
        invoices: [],
        invoicePages: [],
        glossaryEntries: [],
        videos: [],
      },
    });
  }

   async init() {
    const tenantDomain = await new Parse.Query(TenantDomain)
      .equalTo("domain", _optionalChain([window, 'optionalAccess', _ => _.location, 'optionalAccess', _2 => _2.hostname]))
      .first();

    const tenant = _optionalChain([tenantDomain, 'optionalAccess', _3 => _3.get, 'call', _4 => _4("tenant")]);
    const tenantId = _optionalChain([tenant, 'optionalAccess', _5 => _5.id]);

    if (!tenant || !tenantId) {
      this.setLoading(false);

      this.store.update((draft) => {
        draft.tenantIdNotFound = true;
      });

      return;
    }

    const tenantConfig = await new Parse.Query(TenantConfig)
      .equalTo("tenant", tenant)
      .first();

    const invoices = await new Parse.Query(Invoice)
      .equalTo("tenant", tenant)
      .ascending("title")
      .find();

    const invoicePages = await new Parse.Query(InvoicePage)
      .equalTo("tenant", tenant)
      .descending("order")
      .find();

    const glossaryEntries = await new Parse.Query(GlossaryEntry)
      .equalTo("tenant", tenant)
      .equalTo('language', 'de')
      .limit(999999999999)
      .find();

      glossaryEntries.sort(function(a, b) {
        var keyA = a.get("title").toUpperCase(),
          keyB = b.get("title").toUpperCase();
        // Compare the 2 dates
        


        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });


    const videos = await new Parse.Query(Video)
      .equalTo("tenant", tenant)
      .ascending("title")
      .find();

    this.store.update((draft) => {
      draft.tenantConfig = tenantConfig;
      draft.invoices = invoices;
      draft.invoicePages = invoicePages;
      draft.glossaryEntries = glossaryEntries;
      draft.videos = videos;
    });

    this.setLoading(false);
  }
}

export const app = new AppService();

const defaultSelector = (state) => state;



export function useAppState(selector) {
  if (selector) {
    return useServiceStore(app, selector);
  } else {
    return useServiceStore(app, defaultSelector) ;
  }
}
