import Parse from "parse";




















export class TenantConfig extends Parse.Object {
  constructor(data) {
    super("APP_TenantConfig", data );
  }
}

Parse.Object.registerSubclass("APP_TenantConfig", TenantConfig);
