const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/GlossaryRoute.tsx";import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";


import {
  Collapse,
  Space,
  Anchor,
  Input,
  Row,
  Col,
  Empty,
  Button,
  Typography,
} from "antd";

import { useAppState } from "../state";

import { AppLayout } from "./AppLayout";

import { GlossaryEntryDetails } from "./GlossaryEntryDetails";


const { Panel } = Collapse;
const { Link } = Anchor;
const { Search } = Input;
const { Title } = Typography;
var lastLetter = "";
var searchEmpty = false;

export const GlossaryRoute = React.memo(function GlossaryRoute() {
  const { glossaryEntries } = useAppState();
  const [glossarArray, setGlossarArray] = React.useState(
    []
  );
  const { id, letterID } = useParams();
  const navigate = useNavigate();

  const selectedEntry = glossaryEntries.find(
    (entry) => entry.get("keyword") === id
  );

  function onSearch(input) {
    lastLetter = "";
    if (input.length > 0) {
      var filterListed = [];
      filterListed = glossaryEntries.filter((name) =>
        name.get("title").toUpperCase().includes(input.toUpperCase())
      );
      if (filterListed.length == 0) {
        searchEmpty = true;
      } else {
        searchEmpty = false;
      }
      setGlossarArray(filterListed);
    } else {
      searchEmpty = false;
      setGlossarArray(glossaryEntries);
    }
  }

  function getIndexView(letter) {
    var returnValue = React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}});
    if (lastLetter != letter) {
      lastLetter = letter;
      returnValue = React.createElement('h1', { id: letter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, letter);
    }
    return returnValue;
  }

  function resetSearch() {
    searchEmpty = false;
    setGlossarArray(glossaryEntries);
  }

  React.useEffect(() => {
    setGlossarArray(glossaryEntries);
  }, [glossaryEntries]);

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
      /* Page Header */
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Glossar - Finden Sie hier eine Erklärung zu allen wichtigen Begriffen"

        )
      )
      /* Springer */
      , React.createElement('div', {
        style: {
          padding: "10px",
          paddingTop: "20px",
          paddingBottom: "20px",
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      
        , React.createElement(Anchor, {
          affix: false,
          offsetTop: 85,
          style: {
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        
          , React.createElement('div', {
            style: {
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              margin: "10px auto",
              position: "relative",
              textAlign: "center",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          
            , [
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "#",
            ].map((X) => (
              React.createElement(Link, { title: X, href: "#" + X, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}, " "

              )
            ))
          )
        )
      )
      /* Search */
      , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
        , React.createElement(Col, {
          xs: { span: 0 },
          sm: { span: 0 },
          md: { span: 4 },
          lg: { span: 6 },
          xl: { span: 6 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
        )
        , React.createElement(Col, {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 16 },
          lg: { span: 12 },
          xl: { span: 12 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
        
          , React.createElement('div', { style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
            , React.createElement(Search, {
              id: "searchglossar",
              placeholder: "Glossar durchsuchen" ,
              size: "large",
              allowClear: true,
              style: {
                paddingLeft: "10px",
                paddingRight: "10px",
              },
              onSearch: onSearch,
              enterButton: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
            )
          )
        )
        , React.createElement(Col, {
          xs: { span: 0 },
          sm: { span: 0 },
          md: { span: 4 },
          lg: { span: 6 },
          xl: { span: 6 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
        )
      )
      /* Content */

      , React.createElement('div', { style: { width: "100%;", padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
        , searchEmpty ? (
          React.createElement('div', { style: { paddingTop: "50px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
            , React.createElement(Empty, {
              imageStyle: {
                height: 100,
              },
              description: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}, "Keine passenden Einträge gefunden!"   ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
            
              , React.createElement(Button, { type: "primary", onClick: resetSearch, __self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}, "Suche zurücksetzen"

              )
            )
          )
        ) : (
          React.createElement(Space, { id: "test", direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 214}}
            , glossarArray.map((glossaryEntry, index) => (
              React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
                , getIndexView(
                  glossaryEntry.get("title").toUpperCase().charAt(0)
                )
                , React.createElement(Collapse, { style: { width: "100%", fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}
                  , React.createElement(Panel, {
                    header: 
                      glossaryEntry.get("title").toUpperCase().charAt(0) +
                      " - " +
                      glossaryEntry.get("title")
                    ,
                    key: glossaryEntry.get("title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 221}}
                  
                    , React.createElement(GlossaryEntryDetails, { entry: glossaryEntry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 229}} )
                  )
                )
              )
            ))
          )
        )
      )
    )
  );
});
