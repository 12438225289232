const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/FrontpageRoute.tsx";import * as React from "react";

import { useNavigate, } from "react-router-dom";

import { useAppState } from "../state";

import { Row, Col } from "antd";

import { AppLayout } from "./AppLayout";
import { Icon } from "@opendash/icons";

export const FrontpageRoute = React.memo(function FrontpageRoute() {
  const navigate = useNavigate();

  const { invoices, invoicePages: invoicePagesAll } = useAppState();

  function getInvoices() {
    var invoicesElements = [];

    invoices.forEach((invoice) => {
      console.log(invoice.id);

      invoicesElements.push(
        React.createElement('div', {
          style: {
            padding: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        
          , React.createElement('div', {
            className: "frontpageboxbig",
            onClick: () => {
              navigate(`/rechnungserklaerer/rechnung/` + invoice.id);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
          
            , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
              , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
                , React.createElement(Icon, {
                  style: {
                    fontSize: "50px",
                  },
                  icon: "fa:file-invoice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
                )
              )
              , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
                , React.createElement('p', {
                  style: {
                    fontSize: "17px",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
                
                  , invoice.get("title")
                )
              )
            )
          )
        )
      );
    });

    return invoicesElements;
  }

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      , React.createElement('div', {
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      
        , React.createElement('div', { style: {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          , React.createElement(Row, { align: "middle", justify: "center", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , getInvoices()
          )
          , React.createElement(Row, { align: "middle", justify: "center", style: {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            , React.createElement('div', {
              style: {
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
            
              , React.createElement('div', {
                className: "frontpagebox",
                onClick: () => {
                  navigate(`/rechnungserklaerer/glossar`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
              
                , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
                    , React.createElement(Icon, {
                      style: {
                        fontSize: "30px",
                      },
                      icon: "fa:text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                    )
                  )
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
                    , React.createElement('p', {
                      style: {
                        fontSize: "15px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
                    , "Glossar"

                    )
                  )
                )
              )
            )

            , React.createElement('div', {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
            
              , React.createElement('div', {
                className: "frontpagebox",
                onClick: () => {
                  navigate(`/rechnungserklaerer/filme`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
              
                , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
                    , React.createElement(Icon, {
                      style: {
                        fontSize: "30px",
                      },
                      icon: "fa:video", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
                    )
                  )
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
                    , React.createElement('p', {
                      style: {
                        fontSize: "15px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
                    , "Filme"

                    )
                  )
                )
              )
            )

            , React.createElement('div', {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
            
              , React.createElement('div', {
                className: "frontpagebox",
                onClick: () => {
                  navigate(`/`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
              
                , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
                    , React.createElement(Icon, {
                      style: {
                        fontSize: "30px",
                      },
                      icon: "fa:link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
                    )
                  )
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
                    , React.createElement('p', {
                      style: {
                        fontSize: "15px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
                    , "Individuell"

                    )
                  )
                )
              )
            )

            , React.createElement('div', {
              style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
            
              , React.createElement('div', {
                className: "frontpagebox",
                onClick: () => {
                  navigate(`/rechnungserklaerer/contact`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
              
                , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}
                    , React.createElement(Icon, {
                      style: {
                        fontSize: "30px",
                      },
                      icon: "fa:address-book", __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
                    )
                  )
                  , React.createElement(Col, { xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}
                    , React.createElement('p', {
                      style: {
                        fontSize: "15px",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}}
                    , "Kontakt"

                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  );
});
