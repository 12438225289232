const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/HeaderComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import { Layout, Menu, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@opendash/icons";
import { useAppState } from "../state";

const { Header } = Layout;
const { SubMenu } = Menu;



export const HeaderComponent = React.memo(function HeaderComponent({}) {
  const items = [
    { key: "1", label: "Startseite", path: "/" },
    {
      key: "2",
      label: "Rechnungserklärer",
      path: "/rechnungserklaerer/rechnung",
    },
    { key: "3", label: "Erklärfilme", path: "/rechnungserklaerer/filme" },
    { key: "5", label: "Glossar", path: "/rechnungserklaerer/glossar" },
    { key: "6", label: "Kontakt", path: "/rechnungserklaerer/contact" },
    { key: "4", label: "Individuell", path: "/" },
  ];

  const { tenantConfig } = useAppState();
  const [naviCurrent, setNaviCurrent] = React.useState("/");
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState(
    items.find((_item) => location.pathname.startsWith(_item.path)).key
  );

  const onClickMenu = (item) => {
    const clicked = items.find((_item) => _item.key === item.key);
    navigate(_optionalChain([clicked, 'optionalAccess', _ => _.path]));
  };

  React.useEffect(() => {
    if (location.pathname == "/") {
      setSelectedKey("1");
    } else {
      var itemPaths = items;
      itemPaths.shift();
      setSelectedKey(
        itemPaths.find((_item) => location.pathname.startsWith(_item.path)).key
      );
    }
  }, [location]);

  const navigate = useNavigate();

  return (
    React.createElement(Header, {
      style: {
        position: "fixed",
        zIndex: 1,
        width: "100%",
        background: "#fff",
        height: "85px",
        justifyContent: "center",
        verticalAlign: "middle",
        paddingLeft: "25px",
        paddingRight: "25px",

        borderBottom: "3px solid #ffe000",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
    
      , React.createElement(Row, {
        style: { height: "100%", width: "100%", padding: "0px", margin: "0px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      
        , React.createElement(Col, {
          xs: 16,
          style: {
            height: "100%",
            width: "100%",
            padding: "0px",
            paddingTop: "10px",
            margin: "0px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        
          , React.createElement(Menu, {
            className: "customMenu",
            triggerSubMenuAction: "click",
            selectedKeys: [selectedKey],
            mode: "horizontal",
            onClick: onClickMenu,
            overflowedIndicator: 
              React.createElement(Icon, {
                style: { fontSize: "20px", marginTop: "20px" },
                icon: "fa:bars", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
              )
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          
            , items.map((item) => (
              React.createElement(Menu.Item, { key: item.key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
                , React.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, item.label)
              )
            ))
          )
          /*   <Menu
            className="customMenu"
            selectedKeys={[location.pathname]}
            triggerSubMenuAction="click"
            mode="horizontal"
            style={{ border: "0px", paddingTop: "10px" }}
            overflowedIndicator={
              <Icon
                style={{ fontSize: "29px", marginTop: "16px" }}
                icon="fa:bars"
              />
            }
          >
            <Menu.Item
              key="home"
              onClick={() => {
                setNaviCurrent("/");
                navigate(`/`);
              }}
            >
              <h4>Startseite</h4>
            </Menu.Item>
            <Menu.Item>
              <NavLink to="/rechnungserklaerer/rechnung">
                <h4>Rechnungserklärer</h4>
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key="video"
              onClick={() => {
                setNaviCurrent("video");
                navigate(`/rechnungserklaerer/filme`);
              }}
            >
              <h4>Erklärfilme</h4>
            </Menu.Item>
            <Menu.Item
              key="individuell"
              onClick={() => {
                setNaviCurrent("individuell");
                navigate(`/`);
              }}
            >
              <h4>Individuell</h4>
            </Menu.Item>
            <Menu.Item
              key="glossar"
              onClick={() => {
                setNaviCurrent("glossar");
                navigate(`/rechnungserklaerer/glossar`);
              }}
            >
              <h4>Glossar</h4>
            </Menu.Item>
          </Menu>*/
        )
        , React.createElement(Col, {
          xs: 8,
          style: {
            height: "100%",
            width: "100%",
            padding: "0px",
            margin: "0px",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
        
          , React.createElement('img', {
            style: {
              padding: "15px",
              maxWidth: "150px",
              float: "right",
              cursor: "pointer",
            },
            onClick: () => {
              navigate(`/`);
            },
            src: _optionalChain([tenantConfig, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("logo"), 'optionalAccess', _4 => _4.url, 'call', _5 => _5()]) || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
          )
        )
      )
    )
  );
});
