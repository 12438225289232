import "antd/dist/antd.min.css";


import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { LocalUserAdapter } from "./adapter/LocalUserAdapter";

import { ContactRoute } from "./components/ContactRoute";
import { InvoiceRoute } from "./components/InvoiceRoute";
import { GlossaryRoute } from "./components/GlossaryRoute";
import { VideoRoute } from "./components/VideoRoute";
import { FrontpageRoute } from "./components/FrontpageRoute";

import { app } from "./state";

import "./parse.config";

init("opendash", async (factory) => {
  // Logo:
  // factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);
  // factory.ui.setLogoImage(require("../assets/logo.png"));
  factory.ui.disableHeader();
  factory.ui.disableFooter();

  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:
  factory.registerLanguage("en", "English");

  factory.registerTranslationResolver(
    "en",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/en/app")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS({ scope: "dev" }));
  factory.registerUserStorageAdapter(new StorageAdapterLS({ scope: "user" }));
  factory.registerUserAdapter(new LocalUserAdapter());

  // Frontpage

  const routes = [
    {
      label: "Frontpage",
      path: "/rechnungserklaerer/start",
      icon: "fa:file-pdf"
    },
    {
      label: "Rechnungen",
      path: "/rechnungserklaerer/rechnung",
      icon: "fa:file-pdf"
    },
    {
      label: "Glossar",
      path: "/rechnungserklaerer/glossar",
      icon: "fa:book"
    },
    {
      label: "Filme",
      path: "/rechnungserklaerer/filme",
      icon: "fa:video"
    },
    {
      label: "Kontakt",
      path: "/rechnungserklaerer/contact",
      icon: "fa:address-book"
    },
    {
      label: "Individuell",
      path: "/rechnungserklaerer/Individuell",
    },
  ];

  for (const route of routes) {
    factory.registerStaticNavigationItem({
      id: route.path,
      group: "frontpage",
      place: "frontpage",
      label: route.label,
      icon: route.icon ? route.icon : "fa:empty-set",
      order: 10,
      color: "#ffe000",
      link: route.path,
      routeCondition: "**",
      activeCondition: "**",
    });
  }

  // Routing
  
  factory.registerRoute({
    path: "/",
    //redirectPath: "/rechnungserklaerer/rechnung",
    component: makeAsync(FrontpageRoute),
  })

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung/:invoiceId",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung/:invoiceId/:pageId",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/filme",
    component: makeAsync(VideoRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/filme/:id",
    component: makeAsync(VideoRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/glossar",
    component: makeAsync(GlossaryRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/glossar/:id",
    component: makeAsync(GlossaryRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/contact",
    component: makeAsync(ContactRoute),
  });
})
  .then(async () => {
    console.log("init open.DASH");

    await app.init();

    console.log("init Rechnungserklärer");
  })
  .catch((error) => {
    console.error("opendash.init error", error);
  });

function makeAsync(comp) {
  return async () => ({ default: comp });
}
