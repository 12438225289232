const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/InvoiceRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";
import styled from "styled-components";

import { useTranslation } from "@opendash/i18n";
import { Collapse, Tabs, Row, Col, Select, Modal, } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAppState } from "../state";

import { AppLayout } from "./AppLayout";
import { useElementSize } from "@opendash/core";
import { GlossaryEntryDetails } from "./GlossaryEntryDetails";
import Title from "antd/lib/typography/Title";
import { Icon } from "@opendash/icons";

const { Option } = Select;

const INVOICE_WIDTH = 2480;
const INVOICE_HEIGHT = 3508;

const ContentContainer = styled.div`
  display: flex;

  .paddingClass {
    padding: 10px;
  }
  .paddingClassWithoutPadding {
    padding: 10px;
  }

  @media only screen and (max-width: 768px) {
    .paddingClass {
      padding: 0px;
      padding-top: 10px;
    }
    .paddingClassWithoutPadding {
      padding: 0px;
    }
  }

  .btntest {
    position: absolute;
    border-radius: 100%;
    border-top-left-radius: 10px;
    background-color: #ffe000;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }


  
  }
`;
const PageContainer = styled.div`
  flex-grow: 0;
  width: 100%;
`;
const ExplainationContainer = styled.div`
  flex-grow: 1;
`;

const PageImageContainer = styled.div`
  position: relative;
  border: 1px solid #d9d9d9;
`;

const PageImageAnnotation = styled.span`
  position: absolute;
  /* left, top and background are calculated */
  height: 30px;

  width: auto;
  min-width: 200px;
  color: black;
  visibility: hidden;

  display: inline-flex;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;

  .iconplacer {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    visibility: visible;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: visibility;
    z-index: 10;
    padding-left: 0px;
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: transform;
    border-top-left-radius: 10px;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 30px;
    height: 30px;
  }

  .iconmarker {
    display: none;
  }

  .iconmarkerr {
    margin-left: -0px;
  }

  @media only screen and (min-width: 768px) {
    :hover {
      .iconmarkerl {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 30px;
        padding-top: 10px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 40px;
        margin-left: -30px;
        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        z-index: 5;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
      .iconmarkerr {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 30px;
        padding-top: 10px;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-left: 15px;

        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        z-index: 5;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .iconplacer {
      min-width: 35px;
      width: 35px;
      height: 35px;
      font-size: 12px;
    }

    :hover {
      .iconmarkerl {
        height: 35px;
        font-size: 12px;
      }
      .iconmarkerr {
        height: 35px;
        font-size: 12px;
      }
    }
  }
`;

export const InvoiceRoute = React.memo(function InvoiceRoute() {
  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  const t = useTranslation();
  const {
    invoices,
    invoicePages: invoicePagesAll,
    glossaryEntries,
  } = useAppState();

  const { invoiceId, pageId } = useParams();

  const [modalStatus, setModalStatus] = React.useState(false);

  const imageContainerRef = React.useRef(null);
  const imageContainerSize = useElementSize(imageContainerRef, 1000);
  const imageContainerScale = imageContainerSize.width / INVOICE_WIDTH;

  const navigate = useNavigate();

  const selectedInvoice = invoices.find((invoice) => invoice.id === invoiceId);

  const invoicePages = React.useMemo(
    () =>
      invoicePagesAll.filter((page) => _optionalChain([page, 'access', _ => _.get, 'call', _2 => _2("invoice"), 'optionalAccess', _3 => _3.id]) === invoiceId),
    [invoiceId]
  );

  const selectedPage = React.useMemo(
    () => invoicePages.find((page) => page.id === pageId),
    [invoicePages, pageId]
  );

  const selectedPageGlossaryEntries = React.useMemo(() => {
    if (!selectedPage) {
      return [];
    }

    const keywords = selectedPage
      .get("annotations")
      .map((annotation) => annotation.keyword);

    return keywords.map((keyword) =>
      glossaryEntries.find((entry) => entry.get("keyword") === keyword)
    );
  }, [glossaryEntries, selectedPage]);

  const [highlightedKeyword, setHighlightedKeyword] = React.useState

(null);

  function setInvoice(key) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${key}`);
    } else {
      navigate(`/rechnungserklaerer/rechnung`);
    }
  }

  function setInvoicePage(key) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}/${key}`);
    } else {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}`);
    }
  }

  function getGlossarView() {
    return (
      React.createElement(Collapse, {
        activeKey: highlightedKeyword ? [highlightedKeyword] : [],
        onChange: (key) => {
          console.log(key);

          if (Array.isArray(key)) {
            setHighlightedKeyword(key[key.length - 1]);
          } else {
            setHighlightedKeyword(key);
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 303}}
      
        , selectedPageGlossaryEntries.map((entry, index) => {
          if (!entry) {
            return null;
          }

          return (
            React.createElement(Collapse.Panel, {
              key: entry.get("keyword"),
              header: 
                React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 324}}
                  , index + 1, " - "  , entry.get("title")
                )
              ,
              children: React.createElement(GlossaryEntryDetails, { entry: entry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 328}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 321}}
            )
          );
        })
      )
    );
  }

  // select invoice if none is seleceted
  React.useEffect(() => {
    if (!invoiceId && invoices.length > 0) {
      setInvoice(invoices[0].id);
    }
  }, [invoices, invoiceId]);

  // handle invoice not found
  React.useEffect(() => {
    if (invoiceId && !selectedInvoice) {
      setInvoice(null);
      //message.error(t("app:invoices.error_invoice_not_found"));
    }
  }, [selectedInvoice, invoiceId]);

  // select page if none is seleceted
  React.useEffect(() => {
    if (!pageId && invoicePages.length > 0) {
      setInvoicePage(invoicePages[0].id);
    }
  }, [invoicePages, pageId]);

  // handle page not found
  React.useEffect(() => {
    if (pageId && !selectedPage) {
      setInvoicePage(null);
      //message.error(t("app:invoices.error_page_not_found"));
    }
  }, [selectedPage, pageId]);

  // handle page change for highlighted keywords
  React.useEffect(() => {
    setHighlightedKeyword(null);
  }, [pageId]);

  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 372}}
      /* Page Header */
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 374}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 375}}, "Rechnungserklärer - Hier finden Sie alle Informationen über Ihre Rechnung"


        )
      )
      , React.createElement(ContentContainer, { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 380}}
        , React.createElement(Row, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 381}}
          , React.createElement(Col, {
            xs: { span: 24, order: 2 },
            sm: { span: 24, order: 2 },
            md: { span: 13, order: 1 },
            lg: { span: 15, order: 1 },
            xl: { span: 15, order: 1 },
            style: { width: "100%" },
            className: "paddingClassWithoutPadding", __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}}
          
            , React.createElement(PageContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 391}}
              /* Image View */
              , selectedPage && (
                React.createElement(PageImageContainer, { ref: imageContainerRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}}
                  , React.createElement('img', {
                    src: _optionalChain([selectedPage, 'access', _4 => _4.get, 'call', _5 => _5("image"), 'optionalAccess', _6 => _6.url, 'call', _7 => _7()]),
                    style: {
                      width: imageContainerSize.width,
                      height: Math.round(INVOICE_HEIGHT * imageContainerScale),
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}
                  )

                  , selectedPage.get("annotations").map((annotation, index) => {
                    const active = annotation.keyword === highlightedKeyword;
                    const background = active
                      ? "rgb(176, 176, 176)"
                      : "rgb(255, 228, 0)"; //Highlight Color
                    const background_alpha = active
                      ? "rgba(176, 176, 176, 0.75)"
                      : "rgba(255, 228, 0, 0.75)"; //Highlight Color
                    const left = Math.round(annotation.x * imageContainerScale);
                    const top = Math.round(annotation.y * imageContainerScale);

                    if (isMobile) {
                      return (
                        React.createElement('span', {
                          className: "btntest",
                          style: {
                            background: background,
                            left,
                            top,
                          },
                          onClick: () => {
                            highlightedKeyword == annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 416}}
                        
                          , index + 1
                        )
                      );
                    } else {
                      return (
                        React.createElement(PageImageAnnotation, {
                          title: _optionalChain([selectedPageGlossaryEntries, 'access', _8 => _8[index], 'optionalAccess', _9 => _9.get, 'call', _10 => _10(
                            "title"
                          )]),
                          style: { left, top },
                          onClick: () => {
                            highlightedKeyword == annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 437}}
                        
                          , React.createElement('span', { className: "iconplacer", style: { background }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 451}}
                            , index + 1
                          )
                          , isMobile ? (
                            React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 455}} )
                          ) : (
                            React.createElement('span', {
                              className: 
                                annotation.x > 1240
                                  ? "iconmarkerr"
                                  : "iconmarkerl"
                              ,
                              style: {
                                background: background_alpha,
                                transform:
                                  annotation.x > 1240
                                    ? "translateX(-100%)"
                                    : "",
                              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 457}}
                            
                              , _optionalChain([selectedPageGlossaryEntries, 'access', _11 => _11[index], 'optionalAccess', _12 => _12.get, 'call', _13 => _13("title")])
                            )
                          )
                        )
                      );
                    }
                  })
                )
              )
            )
          )
          , React.createElement(Col, {
            xs: { span: 24, order: 1 },
            sm: { span: 24, order: 1 },
            md: { span: 11, order: 2 },
            lg: { span: 9, order: 2 },
            xl: { span: 9, order: 3 },
            style: { width: "100%" },
            className: "paddingClass", __self: this, __source: {fileName: _jsxFileName, lineNumber: 482}}
          
            , React.createElement(ExplainationContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 491}}
              /* Invoice Navigation */
              , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 493}}
                , _optionalChain([selectedInvoice, 'optionalAccess', _14 => _14.get, 'call', _15 => _15("id")])
                , invoices.map((invoice) => (
                  React.createElement(Col, {
                    onClick: () => {
                      setInvoice(invoice.id);
                    },
                    xs: 6,
                    style: {
                      cursor: "pointer",
                      padding: "5px",
                      height: "110px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 496}}
                  
                    , React.createElement(Row, {
                      justify: "center",
                      align: "middle",
                      style: {
                        borderBottom:
                          _optionalChain([selectedInvoice, 'optionalAccess', _16 => _16.get, 'call', _17 => _17("title")]) == invoice.get("title")
                            ? "3px solid rgb(255, 224, 0)"
                            : "0px solid black",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 511}}
                    
                      , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 521}}
                        , React.createElement(Icon, {
                          style: {
                            fontSize: "40px",
                          },
                          icon: "fa:file", __self: this, __source: {fileName: _jsxFileName, lineNumber: 522}}
                        )
                      )
                      , React.createElement(Col, {
                        span: 24,
                        style: {
                          lineHeight: "1.1",
                          height: "2.2em",
                          fontSize: "12px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 529}}
                      
                        , invoice.get("title")
                      )
                    )
                  )
                ))
              )
              /* 
              <Select
                onChange={setInvoice}
                size="large"
                style={{ width: "100%" }}
                placeholder="Bitte eine Rechnung wählen"
                defaultValue={
                  selectedInvoice
                    ? selectedInvoice.get("title")
                    : invoices.length > 0
                    ? invoices[0].id
                    : ""
                }
              >
                {invoices.map((invoice) => (
                  <Option value={invoice.id}>{invoice.get("title")}</Option>
                ))}
              </Select>
              */
              /* Invoice Page Navigation */
              /* 
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
               
                <Pagination
                  simple
                  hideOnSinglePage={true}
                  showSizeChanger={false}
                  responsive={true}
                  pageSize={1}
                  onChange={(x) => {
                    setInvoicePage(invoicePages[x - 1].id);
                  }}
                  defaultCurrent={1}
                  total={invoicePages.length}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </div>*/

              , React.createElement(Tabs, {
                size: "large",
                activeKey: pageId,
                onChange: setInvoicePage,
                style: {
                  padding: "5px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}
              
                , invoicePages.map((page) => (
                  React.createElement(Tabs.TabPane, { key: page.id, tab: page.get("title"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 602}} )
                ))
              )

              /* Glossary View */

              , React.createElement(Col, { xs: 0, sm: 0, md: 24, lg: 24, xl: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 608}}
                , getGlossarView()
              )
            )
          )
          , React.createElement(Col, {
            xs: { span: 24, order: 3 },
            sm: { span: 24, order: 3 },
            md: { span: 0, order: 3 },
            lg: { span: 0, order: 3 },
            xl: { span: 0, order: 3 },
            style: { width: "100%" },
            className: "paddingClass", __self: this, __source: {fileName: _jsxFileName, lineNumber: 613}}
          
            , getGlossarView()
          )
        )
      )
      , React.createElement(Modal, {
        visible: modalStatus,
        title: _optionalChain([glossaryEntries
, 'access', _18 => _18.find, 'call', _19 => _19((entry) => entry.get("keyword") === highlightedKeyword)
, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("title")]),
        width: 800,
        bodyStyle: { padding: "5px", height: "350px", overflow: "scroll" },
        destroyOnClose: true,
        footer: null,
        onCancel: () => {
          setModalStatus(false);
          setHighlightedKeyword(null);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 626}}
      
        , React.createElement(GlossaryEntryDetails, {
          entry: glossaryEntries.find(
            (entry) => entry.get("keyword") === highlightedKeyword
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 640}}
        )
      )
    )
  );
});
