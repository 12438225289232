const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/FooterComponent.tsx";import * as React from "react";

import { Affix, Avatar, Tooltip, Modal } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { Icon } from "@opendash/icons";
import { ContactContent } from "./ContactContent";



export const FooterComponent = React.memo(function FooterComponent({}) {
  const [modalStatus, setModalStatus] = React.useState(false);

  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , React.createElement(Tooltip, { title: "Kontakt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , React.createElement(Affix, {
          style: {
            float: "right",
            position: "fixed",
            bottom: "40px",
            right: "40px",
            cursor: "pointer",
          },
          onClick: () => {
            setModalStatus(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
        
          , React.createElement(Avatar, {
            size: { xs: 40, sm: 55, md: 55, lg: 55, xl: 64, xxl: 64 },
            style: {
              color: "#fff",
              backgroundColor: "#ffe000",
              boxShadow:
                "0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d",
              transition: "color .3s",
            },
            icon: React.createElement(Icon, { icon: "fa:user-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          )
        )
      )
      , React.createElement(Footer, {
        style: {
          height: "200px",
          width: "100%",
          backgroundColor: "#ffe000",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
      , React.createElement(Modal, {
        visible: modalStatus,
        title: "Kontakt",
        width: 800,
        bodyStyle: { padding: 0 },
        destroyOnClose: true,
        footer: null,
        onCancel: () => {
          setModalStatus(false);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      
        , React.createElement(ContactContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
      )
    )
  );
});
