const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/GlossaryEntryDetails.tsx";import * as React from "react";
import ReactMarkdown from "react-markdown";









export const GlossaryEntryDetails = React.memo(
  function GlossaryEntryDetails({ entry }) {
    if (!entry) {
      return null;
    }

    return (
      React.createElement('div', { style: { fontWeight: "normal" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(ReactMarkdown, { children: entry.get("text") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
      )
    );
  }
);
