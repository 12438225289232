const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/ContactContent.tsx";import * as React from "react";
import { Descriptions } from "antd";
import { Icon } from "@opendash/icons";
import { useAppState } from "../state";
import ReactMarkdown from "react-markdown";

export const ContactContent = React.memo(function ContactContent() {
  const { tenantConfig } = useAppState();

  if (!tenantConfig) {
    return null;
  }

  return (
    React.createElement('div', { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
                , React.createElement(Icon, { icon: "fa:address-book", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} ), "  Anschrift"

              )
            )
          ,
          style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        
          , React.createElement(ReactMarkdown, { children: tenantConfig.get("addressText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
        )
      )

      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
                , React.createElement(Icon, { icon: "fa:phone-alt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} ), "  Telefon/Mail"

              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        
          , React.createElement(ReactMarkdown, { children: tenantConfig.get("contactText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
        )
      )
      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                , React.createElement(Icon, { icon: "fa:clock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} ), "  Öffnungszeiten"

              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        
          , React.createElement(ReactMarkdown, {
            children: tenantConfig.get("openingHoursText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          )
        )
      )
      , React.createElement(Descriptions, {
        title: "",
        layout: "vertical",
        bordered: true,
        style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
      
        , React.createElement(Descriptions.Item, {
          label: 
            React.createElement('div', { style: { fontSize: "20px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
                , React.createElement(Icon, { icon: "fa:route", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} ), "  Wegbeschreibung"

              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
        
          , React.createElement(ReactMarkdown, {
            children: tenantConfig.get("routeDescriptionText") || "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
          )

          , React.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
            , JSON.stringify(
              {
                _: "TODO: Map richtig einbinden!",
                point: tenantConfig.get("mapGeoPoint"),
                label: tenantConfig.get("mapLabel"),
              },
              null,
              2
            )
          )

          , React.createElement('div', { style: { height: "400px", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            , React.createElement('div', { style: { margin: "0 -24px -16px -24px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
              , React.createElement('iframe', {
                src: "https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d644528.425551093!2d6.8925203741342775!3d50.87395406252683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47bf3ad30d7896cf%3A0x11c79d2632d441d!2sasew%20eupener%20stra%C3%9Fe%20gps!3m2!1d50.941019999999995!2d6.8909199999999995!5e0!3m2!1sde!2sde!4v1629450173025!5m2!1sde!2sde",
                height: "400",
                style: { border: "0", width: "100%" },
                loading: "lazy", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
              )
            )
          )
        )
      )
    )
  );
});
