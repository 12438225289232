const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/ContactRoute.tsx";import * as React from "react";
import { Typography, } from "antd";
import { AppLayout } from "./AppLayout";
import { ContactContent } from "./ContactContent";

const { Title } = Typography;

export const ContactRoute = React.memo(function ContactRoute() {
  return (
    React.createElement(AppLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , React.createElement('div', { style: { padding: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , React.createElement(Title, { level: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Kontakt - Bei Fragen und Anregungen können Sie sich jederzeit bei uns melden"


        )
      )
      , React.createElement(ContactContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}} )
    )
  );
});
