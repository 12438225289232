import Parse from "parse";













export class Invoice extends Parse.Object {
  constructor(data) {
    super("APP_Invoice", data );
  }
}

Parse.Object.registerSubclass("APP_Invoice", Invoice);
