const _jsxFileName = "/Users/nico/Developer/Sonstiges/ASEW/src/js/components/AppLayout.tsx";
import * as React from "react";

import styled from "styled-components";
import { FooterComponent } from "./FooterComponent";
import { HeaderComponent } from "./HeaderComponent";

const HeaderComponentIns = styled.div`
  width: 100%;

  position: fixed;
  z-index: 9999999999;
  top: 0;

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
    border-bottom: 5px solid #ffe000;
  }

  .ant-menu-horizontal {
    border-bottom: 0px;
  }

  .customMenu h4 {
    font-size: 16px;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #f2f2f2;

  @media only screen and (max-width: 768px) {
    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-typography h1,
    h1.ant-typography  {
      font-size: 26px;
    }

    .ant-typography h3,
    h3.ant-typography {
      font-size: 14px;
      margin: 0 !important;
    }
  }

  .ant-anchor-link {
    width: auto;
    float: left;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }

  .ant-anchor-wrapper {
    overflow: initial;
  }

  .ant-anchor-link {
    width: 40px !important;
  }

  .ant-tabs-tab:hover {
    color: #000 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #444 !important;
  }
  .ant-tabs-ink-bar {
    background: #ffe000 !important;
  }

  .frontpageboxbig {
    height: 200px;
    padding-top: 12px;
    width: 175px;
    background-color: rgb(255, 224, 0);
    display: flex;
    color: white;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .frontpageboxbig {
    :hover {
      background-color: white;
      color: rgb(176, 176, 176);
      border: 4px solid rgb(176, 176, 176);
    }
  }

  .frontpagebox {
    height: 80px;
    padding-top: 12px;
    width: 175px;
    background-color: rgb(176, 176, 176);
    display: flex;
    color: white;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .frontpagebox {
    :hover {
      background-color: white;
      color: rgb(255, 224, 0);
      border: 4px solid rgb(255, 224, 0);
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const AppLayout = React.memo(function AppLayout({ children }) {
  return (
    React.createElement('div', { style: { width: "100%", marginTop: "90px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
      , React.createElement(HeaderComponentIns, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
        , React.createElement(HeaderComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 132}})
      )
      , React.createElement(Container, {
        style: {
          marginBottom: "20px",
          maxWidth: "1420px",
          height: "calc(100% - 200px)",
          margin: "auto",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
      
        , children
      )
      , React.createElement(FooterComponent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 144}})
    )
  );
});
